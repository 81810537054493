import { get, post, getServerUrl } from "./request";
import login from "./apimodule/login.js";
import device from "./apimodule/device";
import shop from "./apimodule/shop";
import order from "./apimodule/order";
import coupon from "./apimodule/coupon";
import gift from "./apimodule/gift";
import wxuser from "./apimodule/wxuser";
import station from "./apimodule/station";
import employees from "./apimodule/employees";
import invoice from "./apimodule/invoice";
import statis from "./apimodule/statis";
import integral from "./apimodule/integral";
import drawPrize from "./apimodule/drawPrize";
import purchase from "./apimodule/purchase";
import supplier from "./apimodule/supplier"
import stock from './apimodule/stock'
/**
 * @description api 统一管理
 * @example    全局挂载 app.provide('$api', api) ，     组件引用        const xxx = inject('$api')  xxx.function(xxx)
 */
export default {
  login,
  device,
  shop,
  order,
  coupon,
  gift,
  wxuser,
  station,
  invoice,
  employees,
  statis,
  integral,
  drawPrize,
  purchase,
  supplier,
  stock,
  /**
   * @description 导出 / 二维码生成 方法  有参数就连参数一起返回 否则只返回IP
   * @param {String} params
   * @argument {String} getServerUrl  ip 地址
   * @returns file ip
   */
  toExcel(params) {
    return params ? getServerUrl() + `${params}` : getServerUrl();
  },

  /*油类型模块管理 */
  /**
   * @description 查询所有油品信息 不分页
   *
   * @returns Object
   */
  getAllOilsType() {
    return get("/oil/type/selectAllNotPage");
  },
  /**
   * @description 修改油枪
   * @param {String} params
   * @returns Object
   */
  postUpdateOilGun(params) {
    return post("/oilGun/update", params);
  },
  /**
   * @description 油类枪查询 by ID
   *
   * @returns Object
   */
  getSelectOilGun(params) {
    return get(`/oilGun/select/${params}`);
  },

  /**
   * @description 油类型优惠查询 by ID
   *
   * @returns Object
   */
  getSelectOilsType(params) {
    return get(`/oil/discounts/select/${params}`);
  },
  /**
   * @description 油类型优惠查询所有
   *
   * @returns Object
   */
  getSelectAllOilsType(params) {
    return get(`/oil/discounts/selectAll`, params, true);
  },
  /**
   * @description 油类型优惠添加
   *
   * @returns Object
   */
  postAddOilsType(params) {
    return post("/oil/discounts/add", params);
  },
  /**
   * @description 油类型优惠删除 by ID
   *
   * @returns Object
   */
  getDelOilsType(params) {
    return get(`/oil/discounts/delete/${params}`);
  },

  /* 统计  日报 模块*/
  /**
   * @description 查询当前日期
   * @param {String} params
   * @returns Object
   */
  getSelectNewDay(params) {
    // return get(`/statistics/selectNewDay?${params}`)
    return get(`/statistics/statisticsNew`, params, true);
  },
  /**
   * @description 查询当前班次
   *
   * @returns Object
   */
  getSelectShift() {
    return get(`/shift/selectAll`);
  },
  /**
   * @description 查询当前日期按枪号
   * @param {String} params
   * @returns Object
   */
  getSelectNewDayOil(params) {
    return get(`/statistics/selectNewDayOil?${params}`);
  },
  /**
   * @description 查询当前日期按油类
   * @param {String} params
   * @returns Object
   */
  getSelectNewDayType(params) {
    return get(`/statistics/selectNewDayType?${params}`);
  },
  /**
   * @description 查询具体时间(按日,按班次)
   * @param {String} params
   * @returns Object
   */
  getSelectTime(params) {
    return get(`/statistics/selectTime?${params}`);
  },

  /**菜单管理模块 */

  /**
   * @description 菜单添加
   * @param {Object} params
   * @returns Object
   */
  postAddMenuSave(params) {
    return post("/sys/menu/save", params);
  },

  /**
   * @description 获取菜单列表
   *
   * @returns Object
   */
  getMenuTreeList() {
    return get(`/sys/menu/treeList`, {}, true);
  },
  /**
   * @description 通过id删除菜单
   * @param {String} params
   * @returns Object
   */
  getMenuDelById(params) {
    return get(`/sys/menu/delete/${params}`);
  },
  /**
   * @description 查询菜单列表byid
   * @param {String} params
   * @returns Object
   */
  getRoleMenuInfoById(params) {
    return get(`/sys/role/menus/${params}`, {}, true);
  },
  /**
   * @description 查询菜单信息byid
   * @param {String} params
   * @returns Object
   */
  getMenuInfoById(params) {
    return get(`/sys/menu/${params}`);
  },
  /**
   * @description 修改菜单
   * @param {String} id
   * @param {Object} params
   * @returns Object
   */
  postRoleUpdateMenus(id, params) {
    return post(`/sys/role/updateMenus/${id}`, params);
  },

  /**角色管理模块 */

  /**
   * @description 获取角色列表
   * @param {Object} params
   * @returns Object
   */
  postRoleList(params) {
    return post("/sys/role/list", params, true);
  },
  /**
   * @description 删除角色
   * @param {Object} params
   * @returns Object
   */
  postRoleDelete(params) {
    return post("/sys/role/delete", params);
  },
  /**
   * @description 保存角色
   * @param {Object} params
   * @returns Object
   */
  postRoleSave(params) {
    return post("/sys/role/save", params);
  },
  /**
   * @description 获取角色信息byid
   * @param {String} params
   * @returns Object
   */
  getRoleInfoById(params) {
    return get(`/sys/role/${params}`);
  },
  /**
   * @description 重置角色密码
   * @param {String} params
   * @returns Object
   */
  getResetPassword(params) {
    return get(`/sys/role/resetPassword/${params}`);
  },
  /**
   * @description 重置角色状态
   * @param {String} params
   * @returns Object
   */
  getUpdateStatus(params) {
    return get(`/sys/role/updateStatus/${params}`);
  },
  /**
   * @description 待分配角色列表
   * @returns Object
   */
  getWaitAssignedRoleList() {
    return get(`/sys/role/listAll`);
  },
  /**
   * @description 分配角色byid
   * @returns Object
   */
  postAssignedRole(id, params) {
    return post(`/sys/user/grantRole/${id}`, params);
  },

  /**服务商配置模块 */

  /**
   * @description 获取所有服务商
   * @returns Object
   */
  getPayServiceAll() {
    return get(`/payService/selectAll`, {}, true);
  },
  /**
   * @description 获取所有服务通道
   * @returns Object
   */
  getPayServiceUseAll() {
    return get(`/payServiceUse/selectAll`);
  },
  /**
   * @description 查询一条服务商byid
   * @param {String} params
   * @returns Object
   */
  getPayServiceUseById(params) {
    return get(`/payServiceUse/selectOne?id=${params}`, {}, true);
  },
  /**
   * @description 修改通道
   * @param {String} params
   * @returns Object
   */
  postUpdatePayServiceUse(params) {
    return post(`/payServiceUse/update`, params);
  },

  /**管理员  */

  /**
   * @description 检查用户名
   * @param {Object} params
   * @returns Object
   */
  postCheckUserName(params) {
    return post(`/sys/user/checkUserName`, params);
  },

  /**
   * @description 获取管理员信息byid
   * @param {String} params
   * @returns Object
   */
  getUserInfoById(params) {
    return get(`/sys/user/${params}`);
  },
  /**
   * @description 保存管理员信息
   * @param {Object} params
   * @returns Object
   */
  postUserSave(params) {
    return post(`/sys/user/save`, params);
  },
  /**
   * @description 获取管理员列表
   * @param {Object} params
   * @returns Object
   */
  postUserList(params) {
    return post(`/sys/user/list`, params, true);
  },
  /**
   * @description 删除管理员
   * @param {Object} params
   * @returns Object
   */
  postUserDel(params) {
    return post(`/sys/user/delete`, params);
  },
  /**
   * @description 修改管理员密码
   * @param {Object} params
   * @returns Object
   */
  postUserUpdatePassword(params) {
    return post(`/sys/user/updateUserPwd`, params);
  },

  /**
   * @description 重置管理员密码
   * @param {String} params
   * @returns Object
   */
  getUserResetPassword(params) {
    return get(`/sys/user/resetPassword/${params}`);
  },
  /**
   * @description 重置管理员状态
   * @param {String} params
   * @returns Object
   */
  getUserUpdateStatus(params) {
    return get(`/sys/user/updateStatus/${params}`);
  },

  /**
   * @description 修改管理员头像
   * @param {Object} params
   * @returns Object
   */
  postUserUpdateAvatar(params) {
    return post(`/sys/user/updateAvatar`, params);
  },
};
