import { get, post } from "../request";
/*积分模块 */
export default {
  /**
   * @description 积分添加
   * @param {String} params
   * @returns Object
   */
  postIntegralShopAdd(params) {
    return post(`/pointsExchangeItems/add`, params,true);
  },

  /**
   * @description 积分查询所有 列表
   * @param {String} params
   * @returns Object
   */
  getIntegralShopSelectAll(params) {
    return get(`/pointsExchangeItems/selectAll`, params, true);
  },
  /**
   * @description 积分商品配置删除byid
   * @param {String} params
   * @returns Object
   */
  delIntegralShopConfig(params) {
    return get(`/pointsExchangeItems/del`,params);
  },

  /**
   * @description 更新积分商品修改
   * @param {String} params
   * @returns Object
   */
  postIntegralShopUpdate(params) {
    return post(`/pointsExchangeItems/update`, params,true);
  },

  /**
   * @description 查询积分商品详情
   * @param {String} params
   * @returns Object
   */
  getIntegralShopSelectToAH(params) {
    return get(`/pointsExchangeItems/selectById?id=${params}`);
  },
  /**
   * @description 查询积分商品订单记录
   * @param {String} params
   * @returns Object
   */
  getIntegralShopOrderList(params) {
    return get(`/pointsExchangeOrder/selectAll`, params, true);
  },

  /**积分赠送规则添加/修改 */
  integralConfigAddOrEdit(params) {
    return post(`/points/config/addOrUpdate`, params);
  },
  /*** 查询所有积分赠送配置 */
  queryAllIntegralConfig(params) {
    return get(`/points/config/selectAll`, params);
  },
  /*** 删除积分赠送配置 */
  delIntegralConfig(params) {
    return get(`/points/config/delete`, params);
  },
  /** 查询积分赠送配置详情 */
  queryIntegralConfigDetail(params) {
    return get(`/points/config/selectById`, params);
  },
};
