import { get, post } from "../request";
/**供货商管理模块 */
export default {
  /**
   * @description 查询所有供货商列表 分页
   * @param {String|Number} params
   * @returns Object
   */
  getAllSupplierList(params) {
    return post("/ynProductSupplier/selectSupplierListPage", params, true);
  },

  /**
   * @description 查询供货商 列表不分页
   * @param {String|Number} params
   * @returns Object
   */
  queryAllSupplierNotPage(params) {
    return post(`/ynProductSupplier/selectSupplierListAll`, params);
  },
  /**
   * @description 添加供货商
   * @param {String|Number} params
   * @returns Object
   */
  addSupplier(params) {
    return post(`/ynProductSupplier/addSupplier`,params);
  },

  /*** 修改供货商 */
  editSupplier(params) {
    return post(`/ynProductSupplier/updateSupplier`, params);
  },
  /** 删除供货商 */
  delSupplier(params) {
    return post(`/ynProductSupplier/delete/${params}`);
  },
};
