import { get, post } from "../request";
/**采购管理模块 */
export default {
  /**
   * @description 查询所有采购退货列表
   * @param {String|Number} params
   * @returns Object
   */
  getAllPurchaseList(params) {
    return post("/ynProductPurchaseReturn/selectPurchaseReturnList", params, true);
  },

  /**
   * @description 查询采购退货详情
   * @param {String|Number} params
   * @returns Object
   */
  queryPurchaseDetail(params) {
    return post(`/ynProductPurchaseReturn/getPurchaseReturn/${params}`);
  },
  /**
   * @description 添加采购退货单
   * @param {String|Number} params
   * @returns Object
   */
  addPurchaseReturn(params) {
    return post(`/ynProductPurchaseReturn/addPurchaseReturn`,params);
  },

  /*** 修改采购退货单 */
  editPurchaseReturn(params) {
    return post(`/ynProductPurchaseReturn/updatePurchaseReturn`, params);
  },
  /** 审核采购退货单 */
  toExaminePurchaseReturn(params) {
    return post(`/ynProductPurchaseReturn/examinePurchaseReturn/${params}`);
  },
   /** 删除采购退货单 */
   delPurchaseReturn(params) {
    return post(`/ynProductPurchaseReturn/delPurchaseReturn/${params}`);
  },
};
