
import { get, post } from '../request'
/**发票管理模块 */
export default {
    /**
    * @description 查询所有发票列表
    * @param {String|Number} params 
    * @returns Object
    */
    getAllInvoiceList(params) {
        return post("/invoicing/getInvoiceList", params, true)
    },

    /**
      * @description 查询发票详情
      * @param {String|Number} params 
      * @returns Object
      */
    invoiceDetail(params) {
        return get(`/invoiceOrders/getInvoiceOrdersDetails/${params}`, {}, true)
    },
    /**
     * @description 发票作废
     * @param {String|Number} params 
     * @returns Object
     */
    invoiceCancel(params) {
        return post(`/invoicing/issueRedInvoice?invoiceId=${params}`)
    },

    /*** 开蓝票 */
    openInvoiceBlue(params){
        return post(`/invoicing/getInvoiceBlue`,params)
    },

     /*** 一键开多张蓝票 */
     openMultileInvoiceBlue(params){
        return post(`/invoicing/mergeGetInvoiceBlue`,params)
    },

    /** 发票重新推送 给用户 邮箱 */
    inviocePushToUser(params) {
        return post(`/invoiceOrders/deliveryInvoice`, params)
    },

    /** 车队卡 加油卡 充值订单开票 */
    plateCardOpenInvoice(params) {
        return post(`/invoicing/getInvoiceBlue`, params)
    },

    /** 充值记录的开票 记录列表 */
    rechangeInvoiceRecordsList(params) {
        return post(`/invoicing/getInvoiceListByOrderNo`, params)
    },
    /*** 开票设置详情 */
    openInvoiceSettingDetail(params) {
        return get(`/InvoiceConfig/getInvoiceConfig/${params}`)
    },
    /*** 开票设置添加 */
    openInvoiceSettingAddOrUpdate(params) {

        return post(`/InvoiceConfig/operateInvoiceConfig`, params)
    },
    /*** 开票设置列表 */
    openInvoiceSettingList(params) {
        return post(`/InvoiceConfig/getInvoiceConfigList`, params)
    },
    /** 获取会员用户的开票记录 */
    wxUserOpenInvoiceList(params) {
        return post(`/invoiceOrders/getInvoiceOrdersByUserId`, params)
    },
}