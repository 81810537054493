import { get, post } from "../request";
/**系统管理模块 */
export default {
  /*** 添加抽奖活动 */
  addDrawPrizeActivity(params) {
    return post(`/draw/addDrawActivity`, params);
  },
  /***查询抽奖活动列表 */
  queryDrawPrizeActivityList(params) {
    return get(`/draw/selectAll`, params, true);
  },
  /** 修改抽奖活动 */
  editDrawPrizeActivity(params) {
    return post(`/draw/updateDrawActivity`, params);
  },
  /*** 查询抽奖活动BY 活动id */
  getDrawPrizeActivityByActivityId(params) {
    return get(`/draw/selectDrawActivityById`, params);
  },
  /*** 删除抽奖活动BY 活动id */
  delDrawPrizeActivityByActivityId(params) {
    return get(`/draw/deleteDrawActivity`, params);
  },

  /*** 中奖记录 */
  queryDrawPrizeRecords(params) {
    return get(`/draw/selectDrawPrizeRecordAll`, params, true);
  },
  /*** 奖品使用记录 */
  queryDrawPrizeUsedRecords(params) {
    return get(`/draw/selectDrawRecordAll`, params, true);
  },
  /*** 抽奖活动二维码列表 */
  queryDrawPrizeQrCodeList(params) {
    return get(`/drawQrcode/selectAll`, params, true);
  },
  /***添加/ 修改 二维码 抽奖活动 */
  updateDrawQrCode(params) {
    return get(`/drawQrcode/add`, params);
  },

  /**抽奖 生成动态二维码 */
  createDynamicsQrCode(params) {
    return get(`/drawQrcode/creatQrcode`, params);
  },

  /*** 抽奖次数统计发放数量 */
  drawStatisticsOfDrawTimes(params){
    return get(`/draw/drawPrizeRecordStatic`,params,true)
  },
  /*** 抽奖活动统计发放数量 */
  drawStatisticsOfDrawActivity(params){
    return get(`/draw/drawPrizeStatic`,params,true)
  }

};
