import { get, post } from "../request";
/**库存 管理模块 */
export default {
  /**
   * @description 查询所有调整单列表 分页
   * @param {String|Number} params
   * @returns Object
   */
  getAllStockList(params) {
    return post("/ynProductInventoryAdjust/selectPurchaseReturnList", params, true);
  },

  /**
   * @description 查询调整单 详情
   * @param {String|Number} params
   * @returns Object
   */
  queryStockDetail(params) {
    return post(`/ynProductInventoryAdjust/getInventoryAdjust/${params}` );
  },
  /**
   * @description 添加调整单
   * @param {String|Number} params
   * @returns Object
   */
  addStock(params) {
    return post(`/ynProductInventoryAdjust/addInventoryAdjust`,params,true);
  },

  /*** 修改调整单 */
  editStock(params) {
    return post(`/ynProductInventoryAdjust/updateInventoryAdjust`, params,true);
  },
  /** 删除调整单 */
  delStock(params) {
    return post(`/ynProductInventoryAdjust/delInventoryAdjust/${params}`);
  },
   /** 审核库存调整单 */
   toExamineStockAdjust(params) {
    return post(`/ynProductPurchaseReturn/examinePurchaseReturn/${params}`);
  },
};
